<template>
  <div class="container">
    <div
      class="left-menu"
      :style="{
        width: !isCollapse ? '12%' : '4.5%',
        textAlign: !isCollapse ? 'left' : 'center',
      }"
    >
      <left-list :leftIndex="'5'" :isCollapse="isCollapse"></left-list>
    </div>
    <div
      class="right-content"
      :style="{ width: !isCollapse ? '88%' : '95.5%' }"
    >
      <div class="top-nav">
        <top-header @openMenu="openMenu" :isCollapse="isCollapse"></top-header>
      </div>
      <div class="left-list">
        <div class="left-list-title">
          <span>商品管理</span>
        </div>
        <div class="left-list-item left-list-active" @click="leftClick('1')">
          <span>商品分类</span>
        </div>
        <div class="left-list-item" @click="leftClick('0')">
          <span>商品审核</span>
        </div>
      </div>
      <div class="right-next">
        <div class="title">
          <span>商品分类</span>
        </div>
        <div class="date-search">
          <el-button type="primary" @click="add" v-if="classAuth.addBtn">新增</el-button>
        </div>
        <div class="mt-20">
          <el-table :data="tableData.data" border style="width: 100%">
            <el-table-column prop="id" label="ID" width="120" align="center">
            </el-table-column>
            <el-table-column
              prop="name"
              label="分类名称"
              width="150"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="name"
              label="封面图"
              width="150"
              align="center"
            >
              <template slot-scope="scope">
                 <el-image
                  style="width: 100%;"
                  :src="$store.state.imaUrl + scope.row.image"
                  :preview-src-list="srcList"
                  @click="imageClick($store.state.imaUrl + scope.row.image)"
                >
                </el-image>
              </template>
            </el-table-column>

            <el-table-column
              prop="created_at"
              label="创建时间"
              width="220"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="updated_at"
              label="修改时间"
              width="220"
              align="center"
            >
            </el-table-column>
            <el-table-column label="操作" align="center">
              <template slot-scope="scope">
                <el-button
                  size="mini"
                  type="primary"
                  @click="handleEdit(scope.$index, scope.row)"
                  v-if="classAuth.updateBtn"
                  >编辑</el-button
                >
                <el-button
                  size="mini"
                  type="danger"
                  @click="handleDelete(scope.$index, scope.row)"
                   v-if="classAuth.deleteBtn"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="bottom-paging">
          <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="page"
            :page-sizes="[5, 10, 20, 30, 100]"
            :page-size="num"
            layout="total, prev, pager, next, sizes"
            :total="tableData.total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import leftList from "../../../components/leftList.vue";
import topHeader from "../../../components/header.vue";
export default {
  components: {
    leftList,
    topHeader,
  },
  inject: ["reload"],
  data() {
    return {
      tableData: [],
      isCollapse: false,
      page: 1,
      num: 10,
      authIndex: 0,
      classAuth: {
        addBtn: false,
        updateBtn: false,
        deleteBtn: false,
      },
      classAuthList: [],
      srcList: ['https://fuss10.elemecdn.com/8/27/f01c15bb73e1ef3793e64e6b7bbccjpeg.jpeg'],
    };
  },
  created() {
    this.getList();
    this.getAuthIndex("商品管理");
    this.classAuthList = JSON.parse(localStorage.getItem("quanxian"))[
      this.authIndex
    ];
    this.getClassauth(); //获取该用户所拥有的商品权限
  },
  methods: {
    imageClick(url){
      this.srcList = [];
      this.srcList.push(url);
    },
    getAuthIndex(val) {
      console.log(JSON.parse(localStorage.getItem('quanxian')));
      let qx = JSON.parse(localStorage.getItem("quanxian"));
      for (let i = 0; i < qx.length; i++) {
        if (qx[i].name == val) {
          // console.log(i);
          this.authIndex = i;
        }
      }
    },
    getClassauth() {
      for (let i = 0; i < this.classAuthList.children.length; i++) {
        if (this.classAuthList.children[i].name == "商品分类") {
          for (
            let f = 0;
            f < this.classAuthList.children[i].children.length;
            f++
          ) {
            if (
              this.classAuthList.children[i].children[f].name == "修改商品分类"
            ) {
              this.classAuth.updateBtn = true;
            }
            if (
              this.classAuthList.children[i].children[f].name == "删除商品分类"
            ) {
              this.classAuth.deleteBtn = true;
            }
            if (
              this.classAuthList.children[i].children[f].name == "添加商品分类"
            ) {
              this.classAuth.addBtn = true;
            }
          }
        }
      }
    },
    getList() {
      this.$get("get/category", {
        page: this.page,
        num: this.num,
      }).then((res) => {
        this.tableData = res.data.data;
      });
    },
    add() {
      this.$router.push("/classificationadd");
    },
    menuitemClick(pushUrl) {
      // console.log(this.$route.path == pushUrl);
      if (this.$route.path == pushUrl) {
        this.reload();
      } else if (pushUrl) {
        this.$router.push(pushUrl);
      } else {
        this.$notify.error({
          title: "提示",
          message: "暂无页面",
        });
        this.reload();
      }
    },
    leftClick(index) {
      if (index == 0 && this.$route.path != "/commodity") {
        this.$router.push("/commodity");
      } else if (index == 1) {
        this.$router.push("/classification");
      } else {
        this.reload();
      }
    },
    openMenu() {
      this.isCollapse = !this.isCollapse;
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
    },
    handleEdit(index, row) {
      this.$confirm("是否确认修改?修改后会影响已生成订单和商品", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      }).then(() => {
        this.$router.push({
          name: "classificationedit",
          query: {
            id: row.id,
          },
        });
      });

      //   console.log(index, row);
    },
    handleDelete(index, row) {
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      })
        .then(() => {
          this.$post("del/category", {
            id: row.id,
          }).then((res) => {
            if (res.data.status == 200) {
              this.$notify({
                title: "提示",
                message: "操作成功！",
                type: "success",
              });
              this.reload();
            } else {
              this.$notify.error({
                title: "提示",
                message: res.data.message,
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
      console.log(index, row);
    },
  },
};
</script>

<style lang="scss">
.right-content {
  height: 100vh;
  overflow-x: hidden;
  overflow-y: scroll;
  vertical-align: top;
  display: inline-block;
  &::-webkit-scrollbar {
    display: none;
  }
  .date-search {
    margin-top: 30px;
    .el-button {
      padding: 12px 55px;
    }
    .el-input {
      width: 200px;
    }
    .left,
    .right {
      display: inline-block;
    }
    .right {
      width: 1000px;
      vertical-align: top;
    }
  }
  .left-list {
    width: 10%;
    height: 93vh;
    overflow-x: hidden;
    overflow-y: scroll;
    font-size: 14px;
    border-right: 20px solid #f2f2f2;
    display: inline-block;
    text-align: center;
    &::-webkit-scrollbar {
      display: none;
    }
    &-title {
      margin: 20px 0;
      font-size: 16px;
    }
    &-item {
      padding: 15px 0;
      cursor: pointer;
      transition: all 0.2s;
      &:hover {
        color: #fff;
        background-color: #3291f8;
      }
    }
    &-active {
      color: #fff;
      background-color: #3291f8;
    }
  }
  .right-next {
    height: 93vh;
    width: 88.5%;
    overflow-x: hidden;
    overflow-y: scroll;
    border-top: 20px solid #f2f2f2;
    padding: 20px;
    box-sizing: border-box;
    display: inline-block;
    vertical-align: top;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .title {
    color: #000;
    height: 10px;
    padding-left: 15px;
    line-height: 10px;
    border-left: 5px solid #80c4f8;
    margin-bottom: 10px;
  }

  .top-nav {
    display: flex;
    box-sizing: border-box;
    justify-content: space-between;
    padding: 15px;

    .left-icon {
      i {
        cursor: pointer;
        color: #3291f8;
        font-size: 30px;
      }

      .el-icon-refresh-right {
        margin-left: 30px;
      }
    }

    .right-user {
      font-size: 14px;

      span {
        vertical-align: middle;
        margin-right: 20px;
      }

      .colse-btn {
        cursor: pointer;
        vertical-align: middle;
        border: 1px solid #999;
        display: inline-block;
        padding: 5px 20px;
        border-radius: 50px;

        span {
          margin-right: 10px;
        }

        i {
          vertical-align: middle;
        }
      }
    }
  }
}

.left-menu {
  display: inline-block;

  .iconyonghuzu {
    font-size: 18px;
    margin: 0 5px;
  }
}

.el-menu {
  width: 100%;
  background-color: #20222a;
  height: 100vh;
}

.el-menu-vertical-demo:not(.el-menu--collapse) {
  background-color: #20222a;
  color: #fff;
  height: 100vh;
  width: 100%;
}

.el-menu-item:focus,
.el-menu-item:hover {
  color: #000;
  background-color: #ecf5ff;
}

.el-menu-item {
  color: #fff;

  i {
    color: #3291f8;
  }
}

.el-menu-item.is-active {
  background-color: #ecf5ff;
  color: #20222a;
}

.menu-h5 {
  margin: 20px;
  text-align: center;
  font-size: 20px;
}

.menu-icon {
  width: 100%;
  color: #fff;
  box-sizing: border-box;
  padding: 30px 22px;
}
</style>
